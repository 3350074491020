@tailwind base;
@tailwind components;
@tailwind utilities;


html, body {
  font-family: 'Atkinson Hyperlegible', sans-serif;
  overflow-x: hidden;
}

header {
  font-family: 'Righteous', cursive;
}

.button-font {
  font-family: 'Atkinson Hyperlegible', sans-serif;
}

.process-header-text {
  font-family: 'Inter', sans-serif;
}

/********* Custom SwiperJs Styles  *********/

.swiper-button-prev,
.swiper-button-next {
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  transition: all 250ms;
}
  .swiper-button-next {
    background-image: url(./assets/icons/arrowRight.svg);
  }
  .swiper-button-prev {
    background-image: url(./assets/icons/arrowLeft.svg);
  }

.swiper-button-prev::after,
.swiper-button-next::after {
  display: none;
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
  transform: scale(1.2);
}

.swiper-scrollbar-drag {
  background-color: #2563eb;
}

/********* Custom Scrollbar  *********/

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-track {
  border-radius: 10px;
  background: rgba(0,0,0,0.1);
}
::-webkit-scrollbar-thumb{
  border-radius: 10px;
  background: rgba(0,0,0,0.2);
}
::-webkit-scrollbar-thumb:hover{
  background: rgba(0,0,0,0.4);
}
::-webkit-scrollbar-thumb:active{
  background: rgba(0,0,0,.9);
}


/* Basic Fade in animation */

.animate {
  animation-duration: 0.5s;
  animation-name: animate-fade;
  animation-delay: 0.5s;
  animation-fill-mode: backwards;
}

@keyframes animate-fade {
  0% { opacity: 0; }
  100% { opacity: 1; }
}



@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

/**
  * Box spin
  *
  * @author jh3y - jheytompkins.com
*/
.box-spin {
  -webkit-animation: spin 3s infinite linear;
          animation: spin 3s infinite linear;
  height: 50px;
  position: relative;
  width: 50px; }
  .box-spin:before {
    content: '';
    display: block;
    height: 25px;
    width: 25px;
    -webkit-animation: spin .5s infinite;
            animation: spin .5s infinite;
    background: var(--primary, #fff);
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -25px;
    margin-top: -25px; }




/**
  * Dots Flow Loading Animation 
  *
  * @author jh3y - jheytompkins.com
*/
.dots-flow:before {
  -webkit-animation: dots-flow .85s infinite ease;
          animation: dots-flow .85s infinite ease;
  border-radius: 100%;
  content: '';
  height: 22px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -40px);
          transform: translate(-50%, -40px);
  width: 22px; 
}

@-webkit-keyframes dots-flow {
  0%, 100% {
    -webkit-box-shadow: -26px 32px 0px 0 white, 0px 32px 0 0 white, 26px 32px 0 0 white;
            box-shadow: -26px 32px 0px 0 white, 0px 32px 0 0 white, 26px 32px 0 0 white; }
  35% {
    -webkit-box-shadow: -26px 32px 0 4px #2563eb, 0px 32px 0 0 white, 26px 32px 0 0 white;
            box-shadow: -26px 32px 0 4px #2563eb, 0px 32px 0 0 white, 26px 32px 0 0 white; }
  50% {
    -webkit-box-shadow: -26px 32px 0 0 white, 0px 32px 0 4px #2563eb, 26px 32px 0 0 white;
            box-shadow: -26px 32px 0 0 white, 0px 32px 0 4px #2563eb, 26px 32px 0 0 white; }
  65% {
    -webkit-box-shadow: -26px 32px 0px 0 white, 0px 32px 0 0 white, 26px 32px 0 4px #2563eb;
            box-shadow: -26px 32px 0px 0 white, 0px 32px 0 0 white, 26px 32px 0 4px #2563eb; } }

@keyframes dots-flow {
  0%, 100% {
    -webkit-box-shadow: -26px 32px 0px 0 white, 0px 32px 0 0 white, 26px 32px 0 0 white;
            box-shadow: -26px 32px 0px 0 white, 0px 32px 0 0 white, 26px 32px 0 0 white; }
  35% {
    -webkit-box-shadow: -26px 32px 0 4px #2563eb, 0px 32px 0 0 white, 26px 32px 0 0 white;
            box-shadow: -26px 32px 0 4px #2563eb, 0px 32px 0 0 white, 26px 32px 0 0 white; }
  50% {
    -webkit-box-shadow: -26px 32px 0 0 white, 0px 32px 0 4px #2563eb, 26px 32px 0 0 white;
            box-shadow: -26px 32px 0 0 white, 0px 32px 0 4px #2563eb, 26px 32px 0 0 white; }
  65% {
    -webkit-box-shadow: -26px 32px 0px 0 white, 0px 32px 0 0 white, 26px 32px 0 4px #2563eb;
            box-shadow: -26px 32px 0px 0 white, 0px 32px 0 0 white, 26px 32px 0 4px #2563eb; } }